import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Department, DepartmentService, Pager, RoleUserEnum, Team, TeamService, User, UserService } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'user-component',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends AbstractComponent implements OnInit {
  private selectedPermission: string | null = null;
  private selectedDepartmentId: string | null = null;

  pager: Pager<User> = new Pager<User>({ perPage: 10 });
  listObservable: Observable<Pager<User>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  defaultValue: string = '';

  user: User;
  teams: Array<Team> = new Array<Team>();

  departments: Array<Department> = new Array<Department>();

  roleAdmin: RoleUserEnum = RoleUserEnum.ADMIN;
  roleSupervisor: RoleUserEnum = RoleUserEnum.SUPERVISOR;
  roleAttendant: RoleUserEnum = RoleUserEnum.ATTENDANT;

  labelRoles: string;
  labelDepartment: string;

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    public userService: UserService,
    private teamService: TeamService,
    private departmentService: DepartmentService,
  ) {
    super(injector);
    this.loadingContent = true;
  }

  /***/
  ngOnInit() {
    this.getList();
    this.getListTeam();
    this.getListDepartment();
  }

  /***/
  getList(permission?: string, departmentId?: string) {
    this.loadingContent = true; 
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };

    if (permission) {
      this.searchParams['roles'] = permission;
    }

    if (departmentId) {
      this.searchParams['department'] = departmentId;
    }

    this.userService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<User>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
      
    });
  }

  getListTeam() {
    this.teamService.getList().subscribe({
      next: (list: Array<Team>) => {
        this.teams = list.filter((team: Team) => team.department.removed === false);
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  getDepartmentName(user: User): string {
    const relevantDepartments = this.teams
      .filter((team: Team) => {
        const isAgent = team.agents.some((agent: User) => agent._id === user._id);
        const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === user._id);
        return isAgent || isSupervisor;
      })
      .filter(team => team.department && !team.department.removed)
      .map(team => team.department.name);


    const uniqueDepartmentNames = Array.from(new Set(relevantDepartments)).join(', ');

    return uniqueDepartmentNames || '-';
  }



  getDaysWeek(user: User): string {
    const daysSet = new Set<string>();

    this.teams
      .filter((team: Team) => {
        const isAgent = team.agents.some((agent: User) => agent._id === user._id);
        const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === user._id);
        return (isAgent || isSupervisor) && team.department && !team.department.removed;
      })
      .forEach(team => {
        if (team.monday) daysSet.add('Seg');
        if (team.tuesday) daysSet.add('Ter');
        if (team.wednesday) daysSet.add('Qua');
        if (team.thursday) daysSet.add('Qui');
        if (team.friday) daysSet.add('Sex');
        if (team.saturday) daysSet.add('Sáb');
        if (team.sunday) daysSet.add('Dom');
      });

    const orderedDays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
    const resultDays = orderedDays.filter(day => daysSet.has(day)).join(' - ');

    return resultDays || '-';
  }


  getHours(user: User): string {
    const relevantTeam = this.teams.find((team: Team) => {
      const isAgent = team.agents.some((agent: User) => agent._id === user._id);
      const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === user._id);
      return (isAgent || isSupervisor) && team.department && !team.department.removed;
    });
    const hours = relevantTeam ? `${relevantTeam.startHour} - ${relevantTeam.endHour}` : '-';

    return hours;
  }


  /***/
  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  /***/
  delete(user: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.userService.delete(user._id).subscribe({
          next: (value) => {
            this.getList();
            this.alertService.success();
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  /***/
  showAddUser() {
    this.router.navigate(['/user/new'], { relativeTo: this.route });
  }

  /***/
  edit(user?: any) {
    this.router.navigate([`/user/${user._id}/edit`], { relativeTo: this.route });
  }

  /***/
  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        this.searchString = term;
        return this.userService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe({
      next: (pager: Pager<User>) => {
        this.pager = pager;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  /***/
  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  getRoleClass(role: string): string {
    switch (role) {
      case RoleUserEnum.ADMIN:
        return 'bg-green-200 text-green-600';
      case RoleUserEnum.SUPERVISOR:
        return 'bg-purple-200 text-purple-600';
      default:
        return 'bg-blue-200 text-blue-600';
    }
  }

  toggleUserBlockStatus(user: User) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const updatedUser = { ...user, blocked: !user.blocked };
        this.userService.update(updatedUser._id, updatedUser).subscribe({
          next: () => {
            this.getList();
            this.alertService.success(`Usuário ${updatedUser.blocked ? 'bloqueado' : 'desbloqueado'} com sucesso!`);
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  resetPassword(user: User): void {
    if (user && user._id) {
      this.dialog.open(ResetPasswordComponent, {
        width: '500px',
        height: '420px',
        data: { userId: user._id }
      });
    } else {
      console.error('Usuário não encontrado ou ID não definido');
    }
  }

  verifyAdmin(): boolean {
    return this.getRoles().includes(RoleUserEnum.ADMIN) ? true : false;
  }

  private getListDepartment(): void {
    this.departmentService.getList().subscribe({
      next: (departments: Array<Department>) => {
        this.departments = departments;
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os departamentos. Tente novamente mais tarde'),
    });
  }

  searchGetListByPermission(permission: string): void {
    if (!permission) {
      this.selectedPermission = null;
      this.labelRoles = 'Permissões';
    } else {
      this.selectedPermission = permission;
      this.labelRoles = permission === this.roleAdmin ?
        'Administrador' : permission === this.roleSupervisor ?
          'Supervisor' : permission === this.roleAttendant ?
            'Atendente' : 'Permissões';
    }

    this.getList(this.selectedPermission, this.selectedDepartmentId);
  }

  searchGetListByDepartment(department: Department): void {
    if (!department) {
      this.selectedDepartmentId = null;
      this.labelDepartment = 'Departamentos';
    } else {
      this.selectedDepartmentId = department._id;
      this.labelDepartment = department.name;
    }

    this.getList(this.selectedPermission, this.selectedDepartmentId);
  }
}
