import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule, UserModule as UserModuleLib, UserService } from 'lib-trend-core';
import { ColorPickerModule } from 'ngx-color-picker';
import { UserFormComponent } from './user-form/user-form.component';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';

@NgModule({
  imports: [
    SharedModule,
    ColorPickerModule,
    UserModuleLib,
    UserRoutingModule,
  ],
  declarations: [
    UserComponent,
    UserFormComponent,
  ],
  exports: [
    UserComponent,
    UserFormComponent
  ],
  providers: [UserService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class UserModule { }
